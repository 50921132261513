<template>
  <!-- 开放实验 -->
  <div class="mainpage">
    <div class="head">
      <div class="head-title">安全准入</div>
      <!-- <div class="retutnbt" @click="toback">返回</div> -->
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <el-tabs v-model="activeName" class="eltabs" @tab-click="tabHandleClick">
        <el-tab-pane label="在线学习" name="first"> 
          <Onlinelearn></Onlinelearn>
        </el-tab-pane>
        <el-tab-pane label="在线练习" name="second"> </el-tab-pane>
        <el-tab-pane label="在线考试" name="three"> </el-tab-pane>
      </el-tabs>
    </div>
    <Customdialog
      ref="customdialog"
      class="customdialog"
      width="70%"
      type="table"
      title="开放实验预约"
      :showclose="true"
    >
      <div slot="dialogbody"></div>
    </Customdialog>
  </div>
</template>

<script>
import Customdialog from "@/components/customdialog.vue";
import Onlinelearn from "./onlinelearn/onlinelearn.vue";
export default {
  name: "mainpage",
  components: {
    Customdialog,
    Onlinelearn,
  },
  data() {
    return {
      activeName: "first",
      opreatpanelstate: false,
      radio: "1",
      keyword: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
    };
  },
  computed: {},
  methods: {
    tabHandleClick(tab) {
      // console.log(this.activeName);
      if (this.activeName === "first") {
        // this.$refs.table1.getclassifylist();
        // this.$refs.table1.initList(this.academyid);
      } else if (this.activeName === "second") {
        // this.$refs.table2.getclassifylist();
        // this.$refs.table2.initList(this.academyid);
      }
    },
    bt1() {
      this.$refs.customdialog.dialogopenbt();
    },
    opreatbt() {
      if (this.opreatpanelstate) {
        this.opreatpanelstate = false;
      } else {
        this.opreatpanelstate = true;
      }
    },
    todetail() {
      this.$router.push({
        path: "/openexperiment/experimentdetails",
      });
    },
  },
};
</script>
<style scoped lang="scss">




.mainpage {
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #288add;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 88px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    height: 1008px;
    // background: #f7f7f7;
    background: #ffffff;

    padding-top: 20px;

    .eltabs {
      // margin-top: 40px;
      ::v-deep .el-tabs__item {
        font-size: 16px;
        padding: 0px;
        width: 150px;
        text-align: center;
      }
    }
  }
  .customdialog {
    border: 1px solid red;
    padding: 20px;
    .searchcontain {
      border: 1px solid red;
      height: 56px;
      background: #fcfcfc;
      border: 1px solid #ececec;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .maintext {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 200px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bt {
          width: 64px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #288add;
          border-radius: 2px;
          user-select: none;
          cursor: pointer;
        }
        .restbt {
          margin-right: 10px;
          background: #26b5ff;
          color: white;
        }
        .searchbt {
        }
      }
    }
    .reserve {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .reservebt {
        margin-top: 40px;
        margin-right: 10px;
        width: 64px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #3d84ff;
        color: white;
        border: 1px solid #288add;
        border-radius: 2px;
        user-select: none;
        cursor: pointer;
        &:active {
          background: #26b7ffbe;
        }
      }
    }
    .Table {
      margin-top: 10px;
      padding: 10px;
      // border: 1px solid red;
      .opreatbt {
        border: none;
        background: none;
        margin: 0px;
        padding: 0px;
        color: #3d84ff;
      }
    }
  }
}
.btgroup {
  // border: 1px solid red;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  color: #3d84ff;
  height: 60px;
  width: 100%;
  .bt {
    margin-top: 10px !important;
    width: 100%;
    height: 20px;
    margin: 0px;
    padding: 0px;
    border: none;
  }
}
</style>
